//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VentaServices from "../../../Services/VentaServices";

const ventaServices = new VentaServices();
export default {
  name: "buscarClientesModal",
  props: ["model"],
  components: {
    NuevoClienteModal: () => import("./nuevoClienteModal")
  },
  data() {
    return {
      filtros: {
        busqueda: "",
        categoriaId: "",
        subCategoriaId: ""
      },
      categorias: [],
      subcategorias: [],
      clientes: [],
      clienteSeleccionado: [],
      columnas: [
        {name: "clave", label: 'clave', align: 'left', field: 'clave', sortable: true, headerClasses: 'w10p'},
        {name: "nombre_comercial", label: 'nombre comercial', align: 'left', field: 'nombre_comercial', sortable: true, headerClasses: 'w45p'},
        {name: "email", label: 'email', align: 'left', field: 'email', sortable: true, headerClasses: 'w20p', format: val => val || '--'},
        {name: "categoría", label: 'categoría', align: 'left', field: 'categoria', sortable: true, headerClasses: 'w12p'},
        {name: "subcategoría", label: 'subcategoría', align: 'left', field: 'subcategoria', sortable: true, headerClasses: 'w13p', format: val => val || '--'}
      ],
      nuevoClienteModal: false,
      clientesModal: false,
    }
  },
  watch: {
    model(value) {
      if (value) {
        this.limpiarFiltros();
        this.buscarClientes();
      } else
        this.onClose();
    },
  },
  methods: {
    onClose() {
      this.clientesModal = false;
      this.$emit('on-close');
    },
    async onShow() {
      this.loader(true);
      await this.obtenerCategorias();
      this.loader(false);
      this.$nextTick(() => this.$refs.first.focus());
    },
    limpiarFiltros() {
      this.filtros  = {
        busqueda: "",
        categoriaId: "",
        subCategoriaId: ""
      };
    },
    obtenerCategorias() {
      return ventaServices.obtenerCategorias()
        .then(cats => this.categorias = cats)
        .catch(error => this.alertShow(error, "error"));
    },
    obtenerSubcategorias() {
      this.loader(true);
      let data = {categoria: this.filtros.categoriaId};
      return ventaServices.obtenerSubcategorias(data)
        .then(subcats => this.subcategorias = subcats)
        .catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    buscarClientes(loader = true) {
      if (loader) this.loader(true);

      let filtros = {};
      if (_.isEmpty(this.filtros.busqueda) && _.isEmpty(this.filtros.categoriaId) && _.isEmpty(this.filtros.subCategoriaId))
        filtros = {...this.filtros, limit: 50};
      else
        filtros = this.filtros;

      return ventaServices.buscarClientes(filtros)
        .then(clientes => {
          if (clientes.length === 1)
            this.obtenerCliente(clientes[0]);
          else {
            this.clientes = clientes;
            this.clientesModal = true;
          }
        }).catch(error => {
          this.alertShow(error, "error");
          this.onClose();
        })
        .then(() => {if (loader) this.loader(false)});
    },
    seleccionarClienteRow(evt, row, index) {
      this.obtenerCliente(row);
    },
    seleccionarCliente(details) {
      this.obtenerCliente(details.rows[0]);
    },
    obtenerCliente(row) {
      let cliente = {
        clienteId: row.clienteId || row.cliente_id,
        nombreComercial: row.nombreComercial || row.nombre_comercial,
        registroFecha: row.registroFecha || row.registro_fecha,
        categoria: row.categoria || row.categoriaNombre,
        subcategoria: row.subcategoria || row.subcategoriaNombre,
        creditoHabilitadoNombre: row.creditoHabilitadoNombre || (row.credito_habilitado === 1 ? 'Activado' : 'Desactivado'),
        creditoHabilitado: row.creditoHabilitado || row.credito_habilitado,
        creditoMontoDisponible: row.creditoMontoDisponible || row.credito_monto_disponible,
        email: row.email,
        clave: row.clave,
        precioDefault: row.precio_default || row.precioDefault
      };
      this.nuevoClienteModal = false;
      this.clientesModal = false;
      this.$emit("get-cliente", cliente);
    }
  }
}
